import * as React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout/Layout'
import BannerImage from '../components/Page/BannerImage'
import Donate from '../components/Page/Donate'
import DonateForm from '../components/Form/DonateForm'

const DonatePage = ({location, data}) => {

	// check if we have the donate amount and title in the location.state (will be there if it is set in a Link tag
	// from a donate form
	let donateAmount =  '25.00' // default amount
	let donateTitle = 'Donate form'
	if (location.state) {
		if (location.state.amount) {
			donateAmount = location.state.amount
		}
		if (location.state.title ) {
			donateTitle = location.state.title
		}
	}

	return (
		<Layout>
			<BannerImage image={data.file.childImageSharp.gatsbyImageData} />
			<section className={`flex flex-row items-start flex-wrap pt-12 pb-16 bg-boswell-handwriting`}>
				<div className={`md:w-1/2`}>
					<Donate />
				</div>
				<div className={`md:w-1/2 p-4 md:p-12`}>
					<DonateForm amount={donateAmount} title={donateTitle} />
				</div>
			</section>
		</Layout>
	)
}

export const query = graphql`{
  file(relativePath: {eq: "general/Bozzy-16-9-011.jpg"}) {
    childImageSharp {
      gatsbyImageData(placeholder: DOMINANT_COLOR, layout: FULL_WIDTH)
    }
  }
}
`

export default DonatePage

import React, { forwardRef } from 'react'

const PaypalDonateButton = forwardRef((props, ref) => {
	return (
		<form
			name="boswell_donate"
			action="https://www.paypal.com/donate"
			method="POST"
			target="_top"
			className="flex items-center p-2 border-t-2 border-gray-100 bg-white"
			ref={ref}
		>
			{/* the business variable is the paypal merchant id */}
			<input type="hidden" name="business" value="CUT7FZDUZRXD2" />
			<input type="hidden" name="cmd" value="_donations" />
			<input type="hidden" name="currency_code" value="GBP" />
			<input type="hidden" name="amount" value={props.amount} />
			<input type="hidden" name="item_name" value="The Boswell Book Festival" />
			<input type="hidden" name="item_number" value={props.title} />
			<input type="hidden" name="return" value="https://www.boswellbookfestival.co.uk/donate-thank-you" />
			<input type="hidden" name="cancel-return" value="https://www.boswellbookfestival.co.uk/donate" />
			<input type="hidden" name="rm" value="0" /> {/* 0,1,2 */}

			<button
				type="submit"
				name="paypal_submit"
				title="PayPal - The safer, easier way to pay online!"
				className="p-2 px-3 mx-2 shadow uppercase text-lg text-bbf-dark-green border-2 border-bbf-yellow
				bg-bbf-yellow hover:shadow-md hover:border-bbf-dark-green transition-all flex items-center focus:outline-none focus:ring-4 focus:ring-bbf-yellow focus:ring-opacity-50">
				<span className="mr-2 text-center">Donate £{props.amount}</span>
				{/*<GiftIcon />*/}
			</button>
			<img alt="" src="https://www.paypal.com/en_GB/i/scr/pixel.gif" width="1" height="1" />
		</form>
	)
})

export default PaypalDonateButton

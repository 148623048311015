import React, { useState, useRef } from 'react'
import {validateEmail, validateEmpty, validateDonation, validateUkPostcode} from '../../utils/validation'
import PaypalDonateButton from './PaypalDonateButton'

const DonateForm = props => {

	const [name, setName] = useState('')
	const [nameIsValid, setNameIsValid] = useState(true)
	const [amount, setAmount] = useState(props.amount || '25.00')
	const [amountIsValid, setAmountIsValid] = useState(true)
	const [email, setEmail] = useState('')
	const [emailIsValid, setEmailIsValid] = useState(true)
	const [giftAid, setGiftAid] = useState(false)

	const [captcha, setCaptcha] = useState('')
	const [captchaIsValid, setCaptchaIsValid] = useState(true)

	const [giftAidTitle, setGiftAidTitle] = useState('')
	const [giftAidTitleIsValid, setGiftAidTitleIsValid] = useState(true)
	const [giftAidFirstName, setGiftAidFirstName] = useState('')
	const [giftAidFirstNameIsValid, setGiftAidFirstNameIsValid] = useState(true)
	const [giftAidLastName, setGiftAidLastName] = useState('')
	const [giftAidLastNameIsValid, setGiftAidLastNameIsValid] = useState(true)
	const [giftAidHouseName, setGiftAidHouseName] = useState('')
	const [giftAidHouseNameIsValid, setGiftAidHouseNameIsValid] = useState(true)
	const [giftAidPostcode, setGiftAidPostcode] = useState('')
	const [giftAidPostcodeIsValid, setGiftAidPostcodeIsValid] = useState(true)

	const [formIsValid, setFormIsValid] = useState(false)
	const [showError, setShowError] = useState(false)
	const [showSuccess, setShowSuccess] = useState(false)

	const formName = 'boswell-book-festival-donate'
	const submitUrl = '/donate'

	const paypalDonateButtonRef = useRef()

	const handleInputChange = event => {
		const { name, value } = event.target

		switch (name) {
			case 'amount':
				setAmount(value)
				setAmountIsValid(validateDonation(value))
				break
			case 'captcha':
				setCaptcha(value)
				setCaptchaIsValid(!validateEmpty(value))
				break
			case 'email':
				setEmail(value)
				setEmailIsValid(validateEmail(value))
				break
			case 'name':
				setName(value)
				setNameIsValid(validateEmpty(value))
				break
			case 'title':
				setGiftAidTitle(value)
				setGiftAidTitleIsValid(validateEmpty(value))
				break
			case 'first_name_or_initial':
				setGiftAidFirstName(value)
				setGiftAidFirstNameIsValid(validateEmpty(value))
				break
			case 'last_name':
				setGiftAidLastName(value)
				setGiftAidLastNameIsValid(validateEmpty(value))
				break
			case 'house_name_or_number':
				setGiftAidHouseName(value)
				setGiftAidHouseNameIsValid(validateEmpty(value))
				break
			case 'postcode':
				setGiftAidPostcode(value.toUpperCase())
				setGiftAidPostcodeIsValid(validateUkPostcode(value))
				break
			default:
				break
		}

		handleFormValidation()
	}

	const handleGiftAidInputChange = event => {
		setGiftAid(event.target.checked)
		// setting formIsValid here based on the checkbox checked because running handleFormValidation()
		// does not observe the correct setting for giftAid, instead it appears to run with its previous value
		if (event.target.checked) {
			// set the first and last name in the gift aid section if we have the full name
			if (name.length && !giftAidFirstName && !giftAidLastName) {
				const fullName = name.split(' ', 2)
				setGiftAidFirstName(fullName[0])
				setGiftAidLastName(fullName[1])
				handleFormValidation()
			}
			setFormIsValid(
				validateEmpty(amount) &&
				validateEmail(email) &&
				validateEmpty(giftAidTitle) &&
				validateEmpty(giftAidFirstName) &&
				validateEmpty(giftAidLastName) &&
				validateEmpty(giftAidHouseName) &&
				validateUkPostcode(giftAidPostcode) &&
				!validateEmpty(captcha)
			)
		} else {
			setFormIsValid(
				validateDonation(amount) &&
				validateEmpty(name) &&
				validateEmail(email) &&
				!validateEmpty(captcha)
			)
		}
	}

	const handleFormValidation = () => {
		if (giftAid) {
			setFormIsValid(
				validateDonation(amount) &&
				validateEmail(email) &&
				validateEmpty(giftAidTitle) &&
				validateEmpty(giftAidFirstName) &&
				validateEmpty(giftAidLastName) &&
				validateEmpty(giftAidHouseName) &&
				validateUkPostcode(giftAidPostcode) &&
				!validateEmpty(captcha)
			)
		} else {
			setFormIsValid(
				validateDonation(amount) &&
				validateEmpty(name) &&
				validateEmail(email) &&
				!validateEmpty(captcha)
			)
		}
	}

	const handleSubmit = event => {

		if (formIsValid) {
			event.target.setAttribute('disabled', true)
			submitForm().then(() => {
				event.target.removeAttribute('disabled')
			})
		}
	}

	const encodeForm = (data) => {
		return Object.keys(data)
			.map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
			.join("&");
	}

	const getSubmitDate = () => {
		const date = new Date()
		const year = date.getFullYear().toString().substring(2,4)
		const month = (date.getMonth() + 1).toString().padStart(2, '0')
		const day = date.getDate().toString().padStart(2, '0')

		return `${day}/${month}/${year}`
	}

	const submitForm = async () => {
		// submits netlify form via fetch
		setShowError(false)
		setShowSuccess(false)

		const data = {
			name: name,
			amount: amount,
			email: email,
			giftaid: giftAid,
			title: giftAidTitle,
			first_name_or_initial: giftAidFirstName,
			last_name: giftAidLastName,
			house_name_or_number: giftAidHouseName,
			postcode: giftAidPostcode,
			date: getSubmitDate(),
			form_origin: props.title
		}

		await fetch(submitUrl, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
			body: encodeForm({'form-name': formName, ...data}),
		})
			.then(() => {
				handleSubmitPaypalDonate()
				setShowSuccess(true)
			})
			.catch(() => {
				handleSubmitPaypalDonate()
				setShowError(true)
			})
	}

	const handleSubmitPaypalDonate = () => {
		paypalDonateButtonRef.current?.submit()
	}

	const labelStyles = `text-bbf-dark-green`
	const invalidLabelStyles = `text-bbf-red`
	const inputStyles = `transition-all w-full py-2 px-4 text-xl text-bbf-dark-green shadow-inner border-2 border-bbf-dark-green focus:outline-none focus:ring-4 focus:ring-bbf-mid-green focus:ring-opacity-50`
	const invalidInputStyles = `text-bbf-red border-bbf-red`
	const validationMessageStyles = `transition text-sm text-bbf-red mb-2 opacity-0`

	return (
		<>
			<h2 className={`text-4xl mt-1 mb-8`}>Donate</h2>
			{ props.title && props.title !== 'Donate form' ? (
				<p className={`mb-4 text-lg`}>{props.title}</p>
			) : '' }
			<form
				action="/donate-thank-you"
				method="POST"
				name={formName}
				className=""
				data-netlify={true}
				data-netlify-honeypot="captcha"
			>
				<input type="hidden" name="form-name" value={formName} />
				<input type="hidden" name="form_origin" value={props.title} />
				<input type="hidden" name="date" value={getSubmitDate()} />

				<label htmlFor="amount" className={`flex flex-col items-start`}>
					<p className={`${labelStyles} ${!amountIsValid && invalidLabelStyles}`}>Amount (£)</p>
				</label>
				<input
					type="number"
					name="amount"
					id="amount"
					value={amount}
					placeholder={'25.00'}
					className={`${inputStyles} ${!amountIsValid && invalidInputStyles}`}
					onChange={handleInputChange}
					onKeyUp={handleInputChange}
					required
				/>
				<p className={`${validationMessageStyles} ${!amountIsValid && 'opacity-100'}`}>
					Please enter a valid donation amount
				</p>

				<label htmlFor="name" className={`flex flex-col items-start`}>
					<p className={`${labelStyles} ${!nameIsValid && invalidLabelStyles}`}>Full name</p>
				</label>
				<input
					type="text"
					name="name"
					id="name"
					value={name}
					placeholder={props.namePlaceholderText || 'Avery Kind-Person'}
					className={`${inputStyles} ${!nameIsValid && invalidInputStyles}`}
					onChange={handleInputChange}
					onKeyUp={handleInputChange}
					required
				/>
				<p className={`${validationMessageStyles} ${!nameIsValid && 'opacity-100'}`}>
					Please enter your full name
				</p>

				<label htmlFor="email" className={`flex flex-col items-start`}>
					<p className={`${labelStyles} ${!emailIsValid && invalidLabelStyles}`}>Email address</p>
				</label>
				<input
					type="email"
					name="email"
					id="email"
					value={email}
					placeholder={props.emailPlaceholderText || 'avery.kind-person@helping.us'}
					className={`${inputStyles} ${!emailIsValid && invalidInputStyles}`}
					onChange={handleInputChange}
					onKeyUp={handleInputChange}
					required
				/>
				<p className={`${validationMessageStyles} ${!emailIsValid && 'opacity-100'}`}>
					Please enter a valid email address
				</p>

				<p><strong>Please Gift Aid your donation.</strong></p>
				<p className={`mb-2`}>For every £1 you donate The Boswell Trust can reclaim an additional 25p from HMRC.<br/>Your donation will be worth £{(amount * 1.25).toFixed(2)} with Gift Aid.</p>
				<label htmlFor="gift_aid" className={`${labelStyles} flex flex-row items-center mb-4`}>
					<input
						type="checkbox"
						name="gift_aid"
						id="gift_aid"
						checked={giftAid}
						onChange={handleGiftAidInputChange}
					/>
					<p className={`${labelStyles} font-bold ml-2`}>I want to Gift Aid my donation to The Boswell Trust.</p>
				</label>

				{/* Gift Aid Form */}
				<div className={giftAid ? '' : 'hidden'}>
					<p className={`mb-2`}>
						Gift Aid is reclaimed by the charity from the tax you pay for the current tax year. The following details
						are required by HMRC to identify you as a current UK taxpayer.
					</p>

					<label htmlFor="gift_aid_title" className={`flex flex-col items-start`}>
						<p className={`${labelStyles} ${!giftAidTitleIsValid && invalidLabelStyles}`}>Title</p>
					</label>
					<input
						type="text"
						name="title"
						id="gift_aid_title"
						value={giftAidTitle}
						placeholder={'Mr, Mrs, etc'}
						maxLength={4}
						className={`${inputStyles} ${!giftAidTitleIsValid && invalidInputStyles}`}
						onChange={handleInputChange}
						onKeyUp={handleInputChange}
						required
					/>
					<p className={`${validationMessageStyles} ${!giftAidTitleIsValid && 'opacity-100'}`}>
						Please enter your title, i.e Mr, Miss, Prof
					</p>

					<label htmlFor="gift_aid_first_name_or_initial" className={`flex flex-col items-start`}>
						<p className={`${labelStyles} ${!giftAidFirstNameIsValid && invalidLabelStyles}`}>First name or initial</p>
					</label>
					<input
						type="text"
						name="first_name_or_initial"
						id="gift_aid_first_name_or_initial"
						value={giftAidFirstName}
						placeholder={'Avery'}
						maxLength={35}
						className={`${inputStyles} ${!giftAidFirstNameIsValid && invalidInputStyles}`}
						onChange={handleInputChange}
						onKeyUp={handleInputChange}
						required
					/>
					<p className={`${validationMessageStyles} ${!giftAidFirstNameIsValid && 'opacity-100'}`}>
						Please enter your first name or initial
					</p>

					<label htmlFor="gift_aid_last_name" className={`flex flex-col items-start`}>
						<p className={`${labelStyles} ${!giftAidLastNameIsValid && invalidLabelStyles}`}>Last name</p>
					</label>
					<input
						type="text"
						name="last_name"
						id="gift_aid_last_name"
						value={giftAidLastName}
						placeholder={'Kind-Person'}
						maxLength={35}
						className={`${inputStyles} ${!giftAidLastNameIsValid && invalidInputStyles}`}
						onChange={handleInputChange}
						onKeyUp={handleInputChange}
						required
					/>
					<p className={`${validationMessageStyles} ${!giftAidLastNameIsValid && 'opacity-100'}`}>
						Please enter your last name
					</p>

					<label htmlFor="gift_aid_house_name" className={`flex flex-col items-start`}>
						<p className={`${labelStyles} ${!giftAidHouseNameIsValid && invalidLabelStyles}`}>House name or number</p>
						<input
							type="text"
							name="house_name_or_number"
							id="gift_aid_house_name"
							value={giftAidHouseName}
							placeholder={'Auchendrane, By Ayr'}
							maxLength={40}
							className={`${inputStyles} ${!giftAidHouseNameIsValid && invalidInputStyles}`}
							onChange={handleInputChange}
							onKeyUp={handleInputChange}
							required
						/>
					</label>
					<p className={`${validationMessageStyles} ${!giftAidHouseNameIsValid && 'opacity-100'}`}>
						Please enter your house name or number (the first line of your address)
					</p>

					<label htmlFor="gift_aid_postcode" className={`flex flex-col items-start`}>
						<p className={`${labelStyles} ${!giftAidPostcodeIsValid && invalidLabelStyles}`}>Postcode</p>
						<input
							type="text"
							name="postcode"
							id="gift_aid_postcode"
							value={giftAidPostcode}
							placeholder={props.namePlaceholderText || 'KA7 4TW'}
							className={`${inputStyles} ${!giftAidPostcodeIsValid && invalidInputStyles}`}
							onChange={handleInputChange}
							onKeyUp={handleInputChange}
							required
						/>
					</label>
					<p className={`${validationMessageStyles} ${!giftAidPostcodeIsValid && 'opacity-100'}`}>
						Please enter a valid UK Postcode
					</p>

					<p className={`mb-4`}>I am a UK taxpayer and understand that if I pay less Income Tax and/or Capital Gains Tax
						than the amount of Gift Aid claimed on all my donations in that tax year it is my responsibility to pay any
						difference.</p>
				</div>

				<label className={`hidden`}>
					<p className={`${labelStyles} ${!captchaIsValid && invalidLabelStyles}`}>Captcha</p>
					<input
						type="text"
						name="captcha"
						value={captcha}
						placeholder={
							props.captchaPlaceholderText ||
							"if you can see this, don't fill it in!"
						}
						className={`${inputStyles} ${!captchaIsValid && invalidInputStyles}`}
						onChange={handleInputChange}
						onKeyUp={handleInputChange}
					/>
					<p className={`${validationMessageStyles} ${!captchaIsValid && 'opacity-100'}`}>
						Please do NOT fill this in
					</p>
				</label>

				{showError && (
					<p className="mb-4 py-2 px-4 text-white bg-bbf-red font-semibold">
						There has been a problem submitting your message. Please try again.
					</p>
				)}

				{showSuccess && (
					<p className="mb-4 py-2 px-4 text-white bg-bbf-mid-green-alt font-semibold">
						Thank you! Redirecting to PayPal to complete your donation ...
					</p>
				)}

				<button
					className={`transition-all w-full text-white ${
						formIsValid ? 'bg-bbf-teal' : 'bg-gray-400 cursor-not-allowed'
					} py-3 px-6 uppercase shadow transition-all focus:outline-none focus:ring-4 focus:ring-bbf-teal focus:ring-opacity-50`}
					type="button"
					disabled={!formIsValid}
					onClick={handleSubmit}
				>
					{props.submitButtonText ? props.submitButtonText : 'Donate'} £{amount}
				</button>
			</form>

			<div className={`hidden`}>
				<PaypalDonateButton ref={paypalDonateButtonRef} amount={amount} title={props.title || `Donate form`}/>
			</div>
		</>
	)
}

export default DonateForm
